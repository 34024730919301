export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST'
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS'
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL'

export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST'
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS'
export const EVENT_DETAILS_FAIL = 'EVENT_DETAILS_FAIL'

export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL'

export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST'
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS'
export const EVENT_CREATE_FAIL = 'EVENT_CREATE_FAIL'
export const EVENT_CREATE_RESET = 'EVENT_CREATE_RESET'

export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL'
export const EVENT_UPDATE_RESET = 'EVENT_UPDATE_RESET'