import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEvent } from '../actions/eventActions';
import lvan from '../images/lvan.png';
import mvan from '../images/mvan.png';
import svan from '../images/svan.png';

const BookADate = ({ onClose }) => {
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState('');
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');
    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationMessages, setValidationMessages] = useState({});

    const handleVehicleChange = (e) => {
        setSelectedVehicle(e.target.value);
    };

    const submitHandler = (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);
        setValidationMessages({});

        const newValidationMessages = {};
        if (!startDate) newValidationMessages.startDate = "Start date is required.";
        if (!startLocation) newValidationMessages.startLocation = "Pickup location is required.";
        if (!endLocation) newValidationMessages.endLocation = "Destination is required.";
        if (!selectedVehicle) newValidationMessages.selectedVehicle = "Vehicle size is required.";
        if (!email) newValidationMessages.email = "Email is required.";
        if (!phoneNumber) newValidationMessages.phoneNumber = "Phone number is required.";

        if (Object.keys(newValidationMessages).length > 0) {
            setValidationMessages(newValidationMessages);
            setIsFormSubmitted(false);
            return;
        }

        dispatch(
            createEvent({
                startDate,
                startLocation,
                endLocation,
                selectedVehicle,
                email,
                phoneNumber,
            })
        );
    };

    return (
        <div>
            <div
                data-node-type="commerce-cart-container-wrapper"
                style={{ opacity: 1, transition: "opacity 300ms ease 0s" }}
                className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-modal cart-wrapper"
            >
                <div
                    data-node-type="commerce-cart-container"
                    role="dialog"
                    className="w-commerce-commercecartcontainer cart-container"
                    style={{ transform: "scale(1)", transition: "transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s" }}
                >
                    <div className="w-commerce-commercecartheader cart-header">
                        <h4 className="w-commerce-commercecartheading heading-h4-size">Book A Moving Van 📅</h4>
                        <a
                            onClick={onClose}
                            data-node-type="commerce-cart-close-link"
                            className="w-commerce-commercecartcloselink cart-close-button w-inline-block"
                            role="button"
                            aria-label="Close cart"
                        >
                            <div className="line-square-icon"></div>
                        </a>
                    </div>
                    <div className="w-commerce-commercecartformwrapper cart-form-wrapper">
                        {isFormSubmitted ? (
                            <div className="contact-form-block w-form">
                                <div className="success-message w-form-done" tabIndex="-1" role="region" aria-label="Contact Form success" style={{ display: `block` }}>
                                    <div className="text-center">
                                        <div className="check-circle center"><div className="line-rounded-icon"></div></div>
                                        <div className="heading-h4-size">Your request has been submitted.<br /> We will now reach out to you</div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <form onSubmit={submitHandler} className="w-commerce-commercecartform">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <label className="form-label">Date and Time ⏰</label>
                                            </div>
                                            <div className="d-flex">
                                                <input
                                                    className="form-control"
                                                    type="datetime-local"
                                                    onChange={(e) => setStartDate(e.target.value)}
                                                    value={startDate}
                                                />
                                            </div>
                                            {validationMessages.startDate && <span className="text-danger">{validationMessages.startDate}</span>}
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <div>
                                                <label className="form-label">Pickup location 🅰️</label>
                                                <input
                                                    id="event-title"
                                                    value={startLocation}
                                                    onChange={(e) => setStartLocation(e.target.value)}
                                                    type="text"
                                                    placeholder='4 Eshowe Street, Paulshof, Sandton'
                                                    className="form-control"
                                                />
                                                {validationMessages.startLocation && <span className="text-danger">{validationMessages.startLocation}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <div>
                                                <label className="form-label">Destination 🅱️</label>
                                                <input
                                                    id="event-title"
                                                    value={endLocation}
                                                    onChange={(e) => setEndLocation(e.target.value)}
                                                    type="text"
                                                    placeholder="29 Augrabies Street, Pretoria East"
                                                    className="form-control"
                                                />
                                                {validationMessages.endLocation && <span className="text-danger">{validationMessages.endLocation}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <div>
                                                <label className="form-label">Vehicle Size Required</label>
                                                <div className="row">
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="svan"
                                                            name="vehicle"
                                                            value="svan"
                                                            checked={selectedVehicle === 'svan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="svan">Small Van</label>
                                                        <img src={svan} alt="svan" />
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="mvan"
                                                            name="vehicle"
                                                            value="mvan"
                                                            checked={selectedVehicle === 'mvan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="mvan">Medium Van</label>
                                                        <img src={mvan} alt="mvan" />
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="lvan"
                                                            name="vehicle"
                                                            value="lvan"
                                                            checked={selectedVehicle === 'lvan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="lvan">Large Van</label>
                                                        <img src={lvan} alt="lvan" />
                                                    </div>
                                                </div>
                                                {validationMessages.selectedVehicle && <p className="text-danger">{validationMessages.selectedVehicle}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <div>
                                                <label className="form-label">Email</label>
                                                <input
                                                    id="user-email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    type="email"
                                                    placeholder='hey@coolmoves.co.za'
                                                    className="form-control"
                                                />
                                                {validationMessages.email && <p className="text-danger">{validationMessages.email}</p>}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-4">
                                            <div>
                                                <label className="form-label">Phone number</label>
                                                <input
                                                    id="user-number"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    type="tel"
                                                    placeholder='0659962011'
                                                    className="form-control"
                                                />
                                                {validationMessages.phoneNumber && <p className="text-danger">{validationMessages.phoneNumber}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary btn-add-event" style={{ display: 'block', backgroundColor: 'green' }}>
                                        Book
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookADate;
